'use client';

import { formatBigNumber } from '@utils/formatters';
import clsx from 'clsx';
import { language } from 'i18n';
import { BigNumber } from './number';

type DataItem = {
  label: string;
  value: number;
};

interface BigNumbersSectionProps {
  lng: language;
  data: DataItem[];
  variant: 'skeelo' | 'skoob';
}

const FLOATING_POINTS = 1;

export function BigNumbersSection({
  lng,
  data,
  variant,
}: BigNumbersSectionProps) {
  return (
    <div
      className={clsx(
        'mx-auto flex flex-col flex-wrap items-center justify-center gap-8 px-4 md:flex-row md:gap-12'
      )}
    >
      {data.map((item, index) => {
        const [number, quantifier] = formatBigNumber(
          item.value,
          lng as language,
          FLOATING_POINTS
        );

        const showThousandQuantifier =
          quantifier === 'thousand' && lng === 'en';

        return (
          <div key={index} className="flex items-center">
            <BigNumber
              variant={variant}
              value={Number(number)}
              floatingPoints={FLOATING_POINTS}
              quantifier={showThousandQuantifier ? 'K' : ''}
            />

            <p
              className={clsx(
                'max-w-[110px] text-body-nano md:text-body-micro',
                variant === 'skeelo'
                  ? 'text-text-white-positive'
                  : 'text-text-900'
              )}
            >
              {!showThousandQuantifier && (
                <>
                  <b className={clsx(variant === 'skoob' && 'block')}>
                    {quantifier}
                  </b>{' '}
                </>
              )}
              {item.label}
            </p>
          </div>
        );
      })}
    </div>
  );
}
