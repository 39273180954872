'use client';

import { Format, FormatButton } from '@components/new/formatButton';
import { FormatInfo } from '@components/new/formatInfo';
import { sendGAEvent } from '@utils/ga';
import { language } from 'i18n';
import { useState } from 'react';

const FORMATS = ['ebooks', 'audiobooks', 'minibooks', 'comicbooks'];

interface FormatsInfoProps {
  lng: language;
}

export function FormatsInfo({ lng }: FormatsInfoProps) {
  const formats = lng === 'pt' ? FORMATS : FORMATS.slice(0, -1);

  const [activeFormat, setActiveFormat] = useState(formats[0]);

  const handleSetActiveFormat = (format: string) => {
    setActiveFormat(format);

    sendGAEvent(`productb2c_${format}_click`);
  };

  return (
    <div>
      <div className="mb-16 flex items-center justify-center gap-4 px-2 md:mb-20 md:gap-6 md:px-4">
        {formats.map(format => (
          <FormatButton
            key={format}
            format={format as Format}
            isActive={format === activeFormat}
            onClick={() => handleSetActiveFormat(format)}
          />
        ))}
      </div>

      <div className="flex items-center justify-center">
        <FormatInfo lng={lng} format={activeFormat as Format} />
      </div>
    </div>
  );
}
