'use client';

import clsx from 'clsx';
import { useRef } from 'react';
import styles from './styles.module.scss';

import { animate, useInView, useIsomorphicLayoutEffect } from 'framer-motion';

interface BigNumberProps {
  value: number;
  variant: 'skeelo' | 'skoob';
  floatingPoints: number;
  quantifier?: string;
}

export function BigNumber({
  value,
  variant,
  floatingPoints,
  quantifier,
}: BigNumberProps) {
  const ref = useRef<HTMLParagraphElement>(null);

  const inView = useInView(ref, { once: true });

  const quantifierDisplay = !!quantifier ? quantifier : '';

  useIsomorphicLayoutEffect(() => {
    const element = ref.current;

    if (!element || !inView) return;

    if (window.matchMedia('(prefers-reduced-motion)').matches) {
      element.textContent = value.toFixed(floatingPoints);

      return;
    }

    element.textContent = `+0${quantifierDisplay}`;

    const controls = animate(0, value, {
      duration: 2,
      ease: 'easeOut',
      onUpdate(value) {
        element.textContent = `+${value.toFixed(floatingPoints)}${quantifierDisplay}`;
      },
      onComplete() {
        element.className = `${element.className} ${styles.finished}`;
      },
    });

    return () => controls.stop();
  }, [ref, value, inView]);

  return (
    <p
      ref={ref}
      className={clsx(
        'mr-3 text-[60px] font-light md:mr-4 md:text-[80px]',
        styles.number,
        variant
      )}
    >
      +0{quantifierDisplay}
    </p>
  );
}
