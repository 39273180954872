'use client';

import { Audiobook3 } from '@components/Icon/Audiobook3';
import { Comeeks2 } from '@components/Icon/Comeeks2';
import { Ebook3 } from '@components/Icon/Ebook3';
import { Minibook2 } from '@components/Icon/Minibook2';
import { useBreakpoint } from '@hooks/useBreakpoint';
import clsx from 'clsx';
import { language } from 'i18n';
import { useTranslation } from 'i18n/client';
import Image from 'next/image';
import { Trans } from 'react-i18next/TransWithoutContext';

export type Format = 'ebooks' | 'audiobooks' | 'minibooks' | 'comicbooks';

const ICONS = {
  ebooks: Ebook3,
  audiobooks: Audiobook3,
  minibooks: Minibook2,
  comicbooks: Comeeks2,
};

interface FormatInfoProps {
  format: Format;
  lng: language;
}

export function FormatInfo({ lng, format }: FormatInfoProps) {
  const { t } = useTranslation(lng, 'home');

  const FORMATS_TITLES = {
    ebooks: t('formats.ebooks.title'),
    audiobooks: t('formats.audiobooks.title'),
    minibooks: t('formats.minibooks.title'),
    comicbooks: t('formats.comicbooks.title'),
  };

  const FORMATS_TEXTS_KEYS = {
    ebooks: 'formats.ebooks.text',
    audiobooks: 'formats.audiobooks.text',
    minibooks: 'formats.minibooks.text',
    comicbooks: 'formats.comicbooks.text',
  };

  const breakpoint = useBreakpoint();

  const isMobile = breakpoint === 'mobile';

  const title = FORMATS_TITLES[format];

  const textKey = FORMATS_TEXTS_KEYS[format];

  const Icon = ICONS[format];

  const iconSize = isMobile ? 32 : 48;

  const IMAGE_PROPS = {
    quality: 100,
    src: `/img/mockups/${format}-${lng}.svg`,
    alt: t('illustration', {
      format: lng === 'en' ? title : title.toLowerCase(),
    }),
    className: 'h-full w-full',
  };

  const IMAGE_SIZES = {
    ebooks: {
      width: 250,
      height: 305,
    },
    audiobooks: {
      width: 290,
      height: 280,
    },
    minibooks: {
      width: 218,
      height: 306,
    },
  };

  const imgSize = IMAGE_SIZES[format as keyof typeof IMAGE_SIZES];

  return (
    <div className="flex items-center justify-center px-12 md:w-full md:max-w-[1016px] md:px-11 lg:px-10">
      <div className="mx-auto flex w-full flex-col items-center rounded-[32px] bg-brand-100-positive px-5 py-8 md:relative md:flex-row md:items-end md:py-0 md:pl-10 md:pr-0 lg:pt-[67px]">
        <div
          className={clsx(
            'mt-[-46px] flex justify-center md:ml-0 md:max-w-[220px] md:flex-1 lg:absolute lg:bottom-[50%] lg:z-10 lg:mb-0 lg:mt-0 lg:w-full',
            lng === 'en'
              ? (format === 'ebooks' &&
                  'md:max-w-[250px] lg:translate-y-[110px]') ||
                  (format === 'audiobooks' &&
                    'md:max-w-[290px] md:translate-y-[-40px] lg:translate-y-[90px]') ||
                  (format === 'minibooks' &&
                    'md:max-w-[218px] md:translate-x-[30px] lg:translate-y-[100px]')
              : 'ml-[-60px] lg:max-w-[272px] lg:translate-y-[50%]'
          )}
        >
          <Image
            width={lng === 'en' ? imgSize.width : 272}
            height={lng === 'en' ? imgSize.height : 272}
            {...IMAGE_PROPS}
          />
        </div>

        <div className="mt-7 flex flex-col justify-end md:relative md:z-20 md:ml-auto md:mt-0 md:flex-1 md:pl-[71px] md:pt-[29px] lg:max-w-[553px] lg:pl-0 lg:pt-0">
          <p className="mb-6 flex items-center justify-center bg-brand-100-positive md:mb-8 md:justify-start">
            <span className="text-brand-500-positive">
              <Icon width={iconSize} height={iconSize} />
            </span>
            <span className="ml-2 block text-headline-xsmall text-brand-700 md:ml-3 md:text-headline-medium lg:text-headline-large">
              {title}
            </span>
          </p>

          <div className="mx-auto rounded-[24px] bg-brand-900 p-6 md:mx-0 md:max-w-[553px] md:rounded-[23px] md:rounded-bl-none md:p-7 lg:p-8">
            <p className="text-center text-body-micro text-text-white-positive md:text-left md:text-body-small">
              <Trans i18nKey={textKey} t={t} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
