'use client';

import { useKeenSlider } from 'keen-slider/react';

import { CompanyItem } from '@components/companyItem';
import clsx from 'clsx';
import 'keen-slider/keen-slider.min.css';
import styles from './styles.module.scss';

const IMG_PATH = '/img/publishers';

const PUBLISHERS = [
  { name: 'Gente Editora', image: `${IMG_PATH}/gente.svg` },
  { name: 'Grupo Planeta', image: `${IMG_PATH}/grupo-planeta.svg` },
  { name: 'Alta Books Editora', image: `${IMG_PATH}/alta-books.svg` },
  {
    name: 'Grupo Editorial Record',
    image: `${IMG_PATH}/grupo-editorial-record.svg`,
  },
  {
    name: 'Grupo Companhia das Letras',
    image: `${IMG_PATH}/companhia-das-letras.svg`,
  },
  { name: 'Globo Livros', image: `${IMG_PATH}/globo-livros.svg` },
  { name: 'Rocco', image: `${IMG_PATH}/rocco.svg` },
  { name: 'Aleph', image: `${IMG_PATH}/aleph.svg` },
  { name: 'Global Editora', image: `${IMG_PATH}/global.svg` },
  { name: 'Buzz', image: `${IMG_PATH}/buzz.svg` },
  { name: 'Jovem Nerd', image: `${IMG_PATH}/jovem-nerd.svg` },
  { name: 'Lura', image: `${IMG_PATH}/lura.svg` },
  { name: 'Nacional', image: `${IMG_PATH}/nacional.svg` },
  {
    name: 'Editora Contexto',
    image: `${IMG_PATH}/editora-contexto.svg`,
  },
  {
    name: 'Labrador Editora',
    image: `${IMG_PATH}/labrador-editora.svg`,
  },
  {
    name: 'Arqueiro',
    image: `${IMG_PATH}/arqueiro.svg`,
  },
  {
    name: 'Sextante',
    image: `${IMG_PATH}/sextante.svg`,
  },
  {
    name: 'Excelsior book one',
    image: `${IMG_PATH}/excelsior.svg`,
  },
  {
    name: 'Agir',
    image: `${IMG_PATH}/agir.svg`,
  },
  {
    name: 'Coquetel',
    image: `${IMG_PATH}/coquetel.svg`,
  },
  {
    name: 'Ediouro',
    image: `${IMG_PATH}/ediouro.svg`,
  },
  {
    name: 'Pixel',
    image: `${IMG_PATH}/pixel.svg`,
  },
  {
    name: 'Livros da Alice',
    image: `${IMG_PATH}/livros-da-alice.svg`,
  },
  {
    name: 'Nova Fronteira',
    image: `${IMG_PATH}/nova-fronteira.svg`,
  },
  {
    name: 'Novo Céu',
    image: `${IMG_PATH}/novo-ceu.svg`,
  },
  {
    name: 'Petra',
    image: `${IMG_PATH}/petra.svg`,
  },
  {
    name: 'Trama',
    image: `${IMG_PATH}/trama.svg`,
  },
  {
    name: 'Harper Collins',
    image: `${IMG_PATH}/harper-collins.svg`,
  },
  {
    name: 'Harlequin',
    image: `${IMG_PATH}/harlequin.svg`,
  },
  {
    name: 'Universo dos Livros',
    image: `${IMG_PATH}/universo-dos-livros.svg`,
  },
  {
    name: 'Leya',
    image: `${IMG_PATH}/leya.svg`,
  },
];

const ANIMATION = { duration: 20000, easing: (t: number) => t };

export function PublishersCarousel() {
  const [ref] = useKeenSlider<HTMLDivElement>({
    loop: true,
    mode: 'free',
    slides: {
      spacing: 18,
      perView: 'auto',
    },
    created(s) {
      s.moveToIdx(5, true, ANIMATION);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, ANIMATION);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, ANIMATION);
    },
  });

  return (
    <div ref={ref} className={clsx('keen-slider mt-7 md:mt-10', styles.slider)}>
      {PUBLISHERS.map((publisher, index) => (
        <div
          key={index}
          className={clsx(
            styles.slide,
            'keen-slider__slide w-[135px] min-w-[135px] max-w-[135px] rounded-[15px]'
          )}
        >
          <CompanyItem
            name={publisher.name}
            image={publisher.image}
            applyGrayScaleFilter
            removeGrayScaleOnHover={false}
          />
        </div>
      ))}
    </div>
  );
}
