'use client';

import { KeenSliderOptions, useKeenSlider } from 'keen-slider/react';

import { CompanyItem } from '@components/companyItem';
import clsx from 'clsx';
import 'keen-slider/keen-slider.min.css';
import { useEffect } from 'react';
import styles from './styles.module.scss';

const ANIMATION = { duration: 20000, easing: (t: number) => t };

type Partner = {
  name: string;
  image: string;
};

interface PartnersCarouselProps {
  partners: Partner[];
  options?: KeenSliderOptions;
}

export function PartnersCarousel({ partners, options }: PartnersCarouselProps) {
  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    mode: 'free',
    vertical: false,
    slides: {
      spacing: 18,
      perView: 'auto',
    },
    ...options,
    breakpoints: {
      '(min-width: 1024px)': {
        vertical: true,
      },
    },
    created(s) {
      s.moveToIdx(5, true, ANIMATION);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, ANIMATION);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, ANIMATION);
    },
  });

  useEffect(() => {
    if (instanceRef.current) {
      instanceRef.current?.moveToIdx(5, true);
    }
  }, [instanceRef]);

  return (
    <div ref={ref} className={clsx(styles.slider, 'keen-slider')}>
      {partners.map((client, index) => (
        <div
          key={index}
          className={clsx(styles.slide, 'keen-slider__slide lg:flex-col')}
        >
          <CompanyItem
            name={client.name}
            image={client.image}
            applyGrayScaleFilter
            removeGrayScaleOnHover
          />
        </div>
      ))}
    </div>
  );
}
